<app-window
  windowName="commandLine"
  [head]="headTemplate"
  [body]="bodyTemplate"
  [canHide]="true"
  [initialHide]="true"
  [defaultX]="385"
  [defaultY]="802"
  [defaultWidth]="576"
  [defaultHeight]="64"
></app-window>

<ng-template #headTemplate>Commands / Chat</ng-template>

<ng-template #bodyTemplate>
  <div class="cmd-container">
    <mat-form-field class="mode-select" floatLabel="never">
      <mat-label>Mode</mat-label>
      <mat-select
        [value]="chatMode()"
        panelClass="game"
        (selectionChange)="updateChatMode($event.value)"
      >
        <mat-option value="cmd">Command</mat-option>
        <mat-option value="say">Local</mat-option>
        <mat-option value="party">Party</mat-option>
        <mat-option value="guild">Guild</mat-option>
        <mat-option value="global">Lobby</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="text-entry" floatLabel="never">
      <input
        matInput
        #commandInput
        [value]="currentCommand"
        (keydown.arrowup)="searchCommandHistory($event, 1)"
        (keydown.arrowdown)="searchCommandHistory($event, -1)"
        (keydown.escape)="updateCommand('')"
        (keydown.enter)="sendCommand($event)"
        (input)="updateCommand(commandInput.value)"
        [placeholder]="placeholderTexts[chatMode()] || 'Enter text here...'"
        maxlength="200"
        autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="off"
      />
    </mat-form-field>
  </div>
</ng-template>
