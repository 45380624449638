{
    "Applied": {
        "tooltip": {
            "desc": "You have an effect applied to your weapons.",
            "icon": "bloody-sword",
            "color": "#090"
        },
        "effect": {
            "type": "outgoing",
            "duration": 120,
            "extra": {
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {}
    },
    "NecroticAura": {
        "tooltip": {
            "name": "Necrotic Aura",
            "color": "#0a0",
            "bgColor": "",
            "desc": "Pulsing %potency necrotic damage to nearby enemies.",
            "icon": "bell-shield"
        },
        "effect": {
            "type": "buff",
            "duration": 600,
            "durationScaleStat": "int",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {}
            }
        },
        "effectMeta": {
            "effectRef": "NecroticAura"
        }
    },
    "Lightbath": {
        "tooltip": {
            "desc": "A beacon of radiance.",
            "icon": "abstract-010",
            "color": "#aa0"
        },
        "effect": {
            "type": "buff",
            "duration": 600,
            "extra": {
                "canRemove": true,
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {
            "effectRef": "Lightbath"
        }
    },
    "BuildupHeat": {
        "tooltip": {
            "name": "Buildup Heat",
            "desc": "Heat is building up.",
            "icon": "fire",
            "color": "#400"
        },
        "effect": {
            "type": "incoming",
            "duration": -1,
            "extra": {
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {
            "effectRef": "BuildupHeat"
        }
    },
    "Burning": {
        "tooltip": {
            "desc": "Constantly receiving fire damage.",
            "icon": "fire",
            "color": "#fff",
            "bgColor": "#dc143c"
        },
        "effect": {
            "type": "debuff",
            "duration": 10,
            "extra": {
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {
            "effectRef": "Burning",
            "recentlyRef": "RecentlyBurned"
        }
    },
    "BuildupChill": {
        "tooltip": {
            "name": "Buildup Chill",
            "desc": "Chill is building up.",
            "icon": "cold-heart",
            "color": "#004"
        },
        "effect": {
            "type": "incoming",
            "duration": -1,
            "extra": {
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {
            "effectRef": "BuildupChill"
        }
    },
    "Chilled": {
        "tooltip": {
            "desc": "Actions are 50% slower.",
            "icon": "cold-heart",
            "color": "#fff",
            "bgColor": "#000080"
        },
        "effect": {
            "type": "debuff",
            "duration": 10,
            "extra": {
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {
            "effectRef": "Chilled",
            "recentlyRef": "RecentlyChilled"
        }
    },
    "Frozen": {
        "tooltip": {
            "desc": "Frozen solid.",
            "icon": "cold-heart",
            "color": "#fff",
            "bgColor": "#00d9d2"
        },
        "effect": {
            "type": "debuff",
            "duration": 10,
            "extra": {
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {
            "effectRef": "Frozen",
            "recentlyRef": "RecentlyChilled"
        }
    },
    "BuildupEnergy": {
        "tooltip": {
            "name": "Buildup Energy",
            "desc": "Energy is building up.",
            "icon": "flame-tunnel",
            "color": "#a0a"
        },
        "effect": {
            "type": "incoming",
            "duration": -1,
            "extra": {
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {
            "effectRef": "BuildupEnergy"
        }
    },
    "Overcharged": {
        "tooltip": {
            "name": "",
            "color": "#fff",
            "bgColor": "#a0a",
            "desc": "Stunned. AC/WIL lowered by %potency%. Incoming magical damage increased by 10%.",
            "icon": "flame-tunnel"
        },
        "effect": {
            "type": "debuff",
            "duration": 30,
            "durationScaleStat": "int",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": false,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {},
                "spriteChange": -1
            }
        },
        "effectMeta": {
            "effectRef": "Overcharged",
            "recentlyRef": "RecentlyOvercharged"
        }
    },
    "Bleeding": {
        "tooltip": {
            "name": "",
            "color": "#900",
            "bgColor": "",
            "desc": "Bleeding and taking %potency physical damage per tick.",
            "icon": "bloody-sword"
        },
        "effect": {
            "type": "debuff",
            "duration": 15,
            "durationScaleStat": "int",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": false,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {},
                "spriteChange": -1
            }
        },
        "effectMeta": {
            "effectRef": "Bleeding"
        }
    },
    "Stunned": {
        "tooltip": {
            "desc": "You are stunned.",
            "icon": "knockout",
            "color": "#990"
        },
        "effect": {
            "type": "debuff",
            "duration": 5,
            "extra": {
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {}
    },
    "BlurredVision": {
        "tooltip": {
            "name": "Blurred Vision",
            "desc": "Your perception is lowered.",
            "icon": "worried-eyes",
            "color": "#a00"
        },
        "effect": {
            "type": "debuff",
            "duration": 30,
            "extra": {
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {
            "effectRef": "BlurredVision"
        }
    },
    "LoweredDefenses": {
        "tooltip": {
            "name": "Lowered Defenses",
            "color": "#a00",
            "bgColor": "",
            "desc": "Defenses lowered by %potency%.",
            "icon": "armor-downgrade"
        },
        "effect": {
            "type": "debuff",
            "duration": 7,
            "durationScaleStat": "int",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": false,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {},
                "spriteChange": -1
            }
        },
        "effectMeta": {
            "effectRef": "LoweredDefenses"
        }
    },
    "Immobilized": {
        "tooltip": {
            "desc": "You are immobilized and unable to move.",
            "icon": "web-spit",
            "color": "#fff",
            "bgColor": "#000"
        },
        "effect": {
            "type": "debuff",
            "duration": 5,
            "extra": {
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {
            "effectRef": "Immobilized",
            "recentlyRef": "RecentlyImmobilized"
        }
    },
    "MinerFever": {
        "tooltip": {
            "name": "Miner Fever",
            "desc": "You feel a bit woozy.",
            "icon": "screaming",
            "color": "#f00"
        },
        "effect": {
            "type": "debuff",
            "duration": 15,
            "extra": {
                "unique": true,
                "statChanges": {
                    "hpregen": -10,
                    "mpregen": -10
                },
                "potency": 0
            }
        },
        "effectMeta": {}
    },
    "FillNormalWater": {
        "tooltip": {
            "name": "Fill Normal Water"
        },
        "effect": {
            "type": "useonly",
            "extra": {
                "potency": 1
            },
            "duration": 0
        },
        "effectMeta": {
            "effectRef": "FillNormalWater"
        }
    },
    "FillSpringWater": {
        "tooltip": {
            "name": "Fill Spring Water"
        },
        "effect": {
            "type": "useonly",
            "extra": {
                "potency": 1
            },
            "duration": 0
        },
        "effectMeta": {
            "effectRef": "FillSpringWater"
        }
    },
    "FillChilledWater": {
        "tooltip": {
            "name": "Fill Chilled Water"
        },
        "effect": {
            "type": "useonly",
            "extra": {
                "potency": 1
            },
            "duration": 0
        },
        "effectMeta": {
            "effectRef": "FillChilledWater"
        }
    },
    "FillLava": {
        "tooltip": {
            "name": "Fill Lava"
        },
        "effect": {
            "type": "useonly",
            "extra": {
                "potency": 1
            },
            "duration": 0
        },
        "effectMeta": {
            "effectRef": "FillLava"
        }
    },
    "FillFateWater": {
        "tooltip": {
            "name": "Fill Fate Water"
        },
        "effect": {
            "type": "useonly",
            "extra": {
                "potency": 1
            },
            "duration": 0
        },
        "effectMeta": {
            "effectRef": "FillFateWater"
        }
    },
    "WallSight": {
        "tooltip": {
            "name": "Wall Sight",
            "desc": "You can see everything!",
            "icon": "all-seeing-eye",
            "color": "#a00"
        },
        "effect": {
            "type": "buff",
            "duration": -1,
            "extra": {
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {}
    },
    "WallWalk": {
        "tooltip": {
            "name": "Wall Walk",
            "desc": "You can walk everywhere!",
            "icon": "invisible",
            "color": "#a00"
        },
        "effect": {
            "type": "buff",
            "duration": -1,
            "extra": {
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {}
    },
    "Channeling": {
        "tooltip": {
            "desc": "Channeling a spell.",
            "icon": "vortex",
            "color": "#a0a"
        },
        "effect": {
            "type": "buff",
            "duration": 600,
            "extra": {
                "canRemove": true,
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {
            "castMessage": "You begin channeling a spell...",
            "unapplyMessage": "You stop channeling your spell."
        }
    },
    "FindFamiliar": {
        "tooltip": {
            "name": "Find Familiar",
            "desc": "This creature has familiar(s)!",
            "icon": "uncertainty",
            "color": "#000"
        },
        "effect": {
            "type": "buff",
            "duration": 600,
            "extra": {
                "canRemove": true,
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {
            "effectRef": "FindFamiliar"
        }
    },
    "Song": {
        "tooltip": {
            "desc": "Generic song message.",
            "icon": "uncertainty",
            "color": "#000"
        },
        "effect": {
            "type": "outgoing",
            "duration": 600,
            "extra": {
                "canRemove": true,
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {}
    },
    "Singing": {
        "tooltip": {
            "desc": "Singing a song for nearby creatures.",
            "icon": "musical-notes",
            "color": "#000"
        },
        "effect": {
            "type": "buff",
            "duration": -1,
            "extra": {
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {
            "effectRef": "Singing"
        }
    },
    "TargetSong": {
        "tooltip": {
            "name": "Target Song",
            "desc": "Generic song message.",
            "icon": "uncertainty",
            "color": "#000"
        },
        "effect": {
            "type": "buff",
            "duration": 600,
            "extra": {
                "canRemove": true,
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {}
    },
    "Unshakeable": {
        "tooltip": {
            "desc": "This creature is immovable and cannot be hit by effects such as Prone and Push.",
            "icon": "black-hand-shield",
            "color": "#000"
        },
        "effect": {
            "type": "buff",
            "duration": -1,
            "extra": {
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {}
    },
    "Attribute": {
        "tooltip": {
            "desc": "???",
            "icon": "uncertainty",
            "color": "#000"
        },
        "effect": {
            "type": "incoming",
            "duration": -1,
            "extra": {
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {
            "effectRef": "Attribute"
        }
    },
    "Mood": {
        "tooltip": {
            "desc": "This creature is calm.",
            "icon": "bully-minion",
            "color": "#fff"
        },
        "effect": {
            "type": "buff",
            "duration": -1,
            "extra": {
                "unique": true,
                "doesTick": true,
                "potency": 0
            }
        },
        "effectMeta": {
            "effectRef": "Mood"
        }
    },
    "Dangerous": {
        "tooltip": {
            "desc": "This creature is dangerous! It has above average stats and may strip your gear on death.",
            "icon": "crowned-skull",
            "color": "#fff",
            "bgColor": "#000"
        },
        "effect": {
            "type": "buff",
            "duration": -1,
            "extra": {
                "doesTick": true,
                "unique": true,
                "statChanges": {
                    "hpregen": 100
                },
                "potency": 0
            }
        },
        "effectMeta": {
            "effectRef": "Dangerous"
        }
    },
    "SummonedPet": {
        "tooltip": {
            "name": "Summoned Pet",
            "desc": "Pet. Summoned by creature.",
            "icon": "eagle-emblem",
            "color": "#000"
        },
        "effect": {
            "type": "buff",
            "duration": -1,
            "extra": {
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {}
    },
    "Invulnerable": {
        "tooltip": {
            "desc": "Taking no damage from any source.",
            "icon": "skull-shield",
            "color": "#fff",
            "bgColor": "#a06"
        },
        "effect": {
            "type": "incoming",
            "duration": -1,
            "extra": {
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {
            "effectRef": "Invulnerable"
        }
    },
    "AcolyteOverseer": {
        "tooltip": {
            "name": "Acolyte Overseer",
            "desc": "Receiving 4% healing per acolyte every 5 seconds.",
            "icon": "minions",
            "color": "#fff",
            "bgColor": "#a06"
        },
        "effect": {
            "type": "buff",
            "duration": -1,
            "extra": {
                "unique": true,
                "doesTick": true,
                "potency": 0
            }
        },
        "effectMeta": {
            "effectRef": "AcolyteOverseer"
        }
    },
    "BrotherlySpeed": {
        "tooltip": {
            "name": "Brotherly Speed",
            "desc": "Blessed by Terwin.",
            "icon": "time-trap",
            "color": "#fff",
            "bgColor": "#a06"
        },
        "effect": {
            "type": "buff",
            "duration": -1,
            "extra": {
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {
            "effectRef": "Haste"
        }
    },
    "BrotherlyShield": {
        "tooltip": {
            "name": "Brotherly Shield",
            "desc": "Blessed by Telwin.",
            "icon": "bell-shield",
            "color": "#fff",
            "bgColor": "#a06"
        },
        "effect": {
            "type": "buff",
            "duration": -1,
            "extra": {
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {
            "effectRef": "VitalEssence"
        }
    },
    "BrotherlySword": {
        "tooltip": {
            "name": "Brotherly Sword",
            "desc": "Blessed by Takwin.",
            "icon": "fist",
            "color": "#fff",
            "bgColor": "#a06"
        },
        "effect": {
            "type": "buff",
            "duration": -1,
            "extra": {
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {
            "effectRef": "Boost"
        }
    },
    "VampireMistForm": {
        "tooltip": {
            "name": "Vampire Mist Form",
            "color": "#04f",
            "bgColor": "",
            "desc": "Mist form. Invulnerable and pulsing Asper & Disease.",
            "icon": "steam"
        },
        "effect": {
            "type": "buff",
            "duration": -1,
            "durationScaleStat": "int",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": false,
                "unique": true,
                "doesTick": true,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {}
            }
        },
        "effectMeta": {
            "effectRef": "VampireMistForm"
        }
    },
    "ZombieScratch": {
        "tooltip": {
            "name": "Zombie Scratch",
            "desc": "Scratched by a zombie. Will transform if not killed quickly enough.",
            "icon": "bleeding-wound",
            "color": "#a00"
        },
        "effect": {
            "type": "debuff",
            "duration": 30,
            "extra": {
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {
            "effectRef": "ZombieScratch"
        }
    },
    "DecayingCorpse": {
        "tooltip": {
            "name": "Decaying Corpse",
            "desc": "Walking dead. Will take its last step soon.",
            "icon": "shambling-zombie",
            "color": "#a00"
        },
        "effect": {
            "type": "debuff",
            "duration": 300,
            "extra": {
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {
            "effectRef": "DecayingCorpse"
        }
    },
    "Swimming": {
        "tooltip": {
            "desc": "You are swimming. Find land soon or you'll start drowning!",
            "icon": "drowning",
            "color": "#0aa"
        },
        "effect": {
            "type": "debuff",
            "duration": 15,
            "extra": {
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {
            "effectRef": "Swimming"
        }
    },
    "Drowning": {
        "tooltip": {
            "desc": "You are drowning. Find land soon or you'll die!",
            "icon": "drowning",
            "color": "#a00"
        },
        "effect": {
            "type": "debuff",
            "duration": -1,
            "extra": {
                "doesTick": true,
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {
            "effectRef": "Drowning"
        }
    },
    "Sated": {
        "tooltip": {
            "desc": "You feel fine.",
            "icon": "meal",
            "color": "#000"
        },
        "effect": {
            "type": "debuff",
            "duration": 600,
            "extra": {
                "hidden": true,
                "unique": true,
                "persistThroughDeath": true,
                "potency": 0
            }
        },
        "effectMeta": {
            "effectRef": "Sated"
        }
    },
    "Nourishment": {
        "tooltip": {
            "desc": "You feel great.",
            "icon": "meal",
            "color": "#000"
        },
        "effect": {
            "type": "buff",
            "duration": 21600,
            "extra": {
                "canRemove": true,
                "unique": true,
                "persistThroughDeath": true,
                "potency": 0
            }
        },
        "effectMeta": {
            "effectRef": "Nourishment",
            "unapplyMessage": "Your nourishment fades."
        }
    },
    "Malnourished": {
        "tooltip": {
            "desc": "Seek food or drink. -1 STR/DEX/AGI/INT/WIS/WIL/HPREGEN/MPREGEN.",
            "icon": "meal",
            "color": "#f00"
        },
        "effect": {
            "type": "debuff",
            "duration": -1,
            "extra": {
                "unique": true,
                "persistThroughDeath": true,
                "statChanges": {
                    "hpregen": -1,
                    "mpregen": -1,
                    "str": -1,
                    "dex": -1,
                    "agi": -1,
                    "int": -1,
                    "wis": -1,
                    "wil": -1
                },
                "potency": 0
            }
        },
        "effectMeta": {
            "effectRef": "Malnourished",
            "applyMessage": "You are starving!"
        }
    },
    "Dead": {
        "tooltip": {
            "desc": "You are dead. Use the restore macro to come back to life. If you wait too long, your body will rot, risking stat loss!",
            "icon": "broken-skull",
            "color": "#f00"
        },
        "effect": {
            "type": "debuff",
            "duration": 500,
            "extra": {
                "unique": true,
                "persistThroughDeath": true,
                "potency": 0
            }
        },
        "effectMeta": {
            "effectRef": "Dead"
        }
    },
    "Encumbered": {
        "tooltip": {
            "desc": "You are encumbered, which inhibits spellcasting and stealth.",
            "icon": "armor-vest",
            "color": "#f00"
        },
        "effect": {
            "type": "debuff",
            "duration": -1,
            "extra": {
                "unique": true,
                "persistThroughDeath": true,
                "statChanges": {
                    "move": -2
                },
                "potency": 0
            }
        },
        "effectMeta": {}
    },
    "LowCON": {
        "tooltip": {
            "name": "Low CON",
            "desc": "Your CON is dangerously low - drink a CON pot or risk getting stripped and losing permanent health.",
            "icon": "glass-heart",
            "color": "#f00"
        },
        "effect": {
            "type": "debuff",
            "duration": -1,
            "extra": {
                "doesTick": true,
                "unique": true,
                "persistThroughDeath": true,
                "potency": 0
            }
        },
        "effectMeta": {
            "applyMessage": "Your consitition is dangerously low.",
            "unapplyMessage": "You are no longer in danger of losing health or getting stripped.",
            "effectRef": "LowCON"
        }
    },
    "EtherManipulation": {
        "tooltip": {
            "name": "Ether Manipulation",
            "desc": "Any items dropped by this character will be transported outside the ether.",
            "icon": "vortex",
            "color": "#f00"
        },
        "effect": {
            "type": "debuff",
            "duration": -1,
            "extra": {
                "doesTick": true,
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {
            "applyMessage": "You enter an ether-heavy area!",
            "unapplyMessage": "The ether here is less powerful.",
            "effectRef": "EtherManipulation"
        }
    },
    "AethericForce": {
        "tooltip": {
            "name": "Aetheric Force",
            "desc": "Any items dropped by this character will be transported outside the ether. Also, this creature will be forcibly removed if they remain too long.",
            "icon": "vortex",
            "color": "#f0f"
        },
        "effect": {
            "type": "debuff",
            "duration": 20,
            "extra": {
                "doesTick": true,
                "unique": true,
                "persistThroughDeath": true,
                "potency": 0
            }
        },
        "effectMeta": {
            "applyMessage": "You enter an area dense with ether!",
            "unapplyMessage": "You breathe a breath of fresh air.",
            "effectRef": "AethericForce"
        }
    },
    "Newbie": {
        "tooltip": {
            "desc": "This player is new! Gain +10% XP to all kills.",
            "icon": "person",
            "color": "#0a0",
            "bgColor": "#000"
        },
        "effect": {
            "type": "debuff",
            "duration": 99999,
            "extra": {
                "unique": true,
                "statChanges": {
                    "xpBonusPercent": 10
                },
                "potency": 0
            }
        },
        "effectMeta": {
            "effectRef": "Newbie"
        }
    },
    "EtherSickness": {
        "tooltip": {
            "name": "Ether Sickness",
            "desc": "Forcibly returned from the Ether and still recovering from your last death.",
            "icon": "pill-drop",
            "color": "#f00"
        },
        "effect": {
            "type": "debuff",
            "duration": 60,
            "extra": {
                "unique": true,
                "statChanges": {
                    "str": -3,
                    "dex": -3,
                    "agi": -3,
                    "int": -3,
                    "wis": -3,
                    "wil": -3,
                    "cha": -3,
                    "luk": -3
                },
                "potency": 0
            }
        },
        "effectMeta": {
            "applyMessage": "You feel sick from the ether infusion!",
            "unapplyMessage": "The ether sickness has worn off."
        }
    },
    "LimitedInvulnerability": {
        "tooltip": {
            "name": "Limited Invulnerability",
            "desc": "Taking no damage from any source temporarily.",
            "icon": "skull-shield",
            "color": "#fff",
            "bgColor": "#0a6"
        },
        "effect": {
            "type": "incoming",
            "duration": 1,
            "extra": {
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {
            "effectRef": "LimitedInvulnerability"
        }
    },
    "Revealed": {
        "tooltip": {
            "desc": "Revealed and cannot hide.",
            "icon": "eye-target",
            "color": "#ccc",
            "bgColor": "#000"
        },
        "effect": {
            "type": "debuff",
            "duration": 5,
            "extra": {
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {}
    },
    "RecentlyStunned": {
        "tooltip": {
            "name": "Recently Stunned",
            "desc": "You were recently stunned.",
            "icon": "knockout",
            "color": "#000"
        },
        "effect": {
            "type": "debuff",
            "duration": 15,
            "extra": {
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {}
    },
    "RecentlyDazed": {
        "tooltip": {
            "name": "Recently Dazed",
            "desc": "You were recently dazed.",
            "icon": "knockout",
            "color": "#000"
        },
        "effect": {
            "type": "debuff",
            "duration": 15,
            "extra": {
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {}
    },
    "RecentlySnared": {
        "tooltip": {
            "name": "Recently Snared",
            "desc": "You were recently snared.",
            "icon": "light-thorny-triskelion",
            "color": "#000"
        },
        "effect": {
            "type": "debuff",
            "duration": 15,
            "extra": {
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {}
    },
    "RecentlyBlinded": {
        "tooltip": {
            "name": "Recently Blinded",
            "desc": "You were recently blinded.",
            "icon": "evil-eyes",
            "color": "#000"
        },
        "effect": {
            "type": "debuff",
            "duration": 15,
            "extra": {
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {}
    },
    "RecentlyShielded": {
        "tooltip": {
            "name": "Recently Shielded",
            "desc": "You were recently shielded.",
            "icon": "aura",
            "color": "#000"
        },
        "effect": {
            "type": "debuff",
            "duration": 15,
            "extra": {
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {}
    },
    "RecentlyBurned": {
        "tooltip": {
            "name": "Recently Burned",
            "desc": "You were recently burned.",
            "icon": "fire",
            "color": "#000"
        },
        "effect": {
            "type": "debuff",
            "duration": 15,
            "extra": {
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {}
    },
    "RecentlyChilled": {
        "tooltip": {
            "name": "Recently Chilled",
            "desc": "You were recently chilled.",
            "icon": "cold-heart",
            "color": "#000"
        },
        "effect": {
            "type": "debuff",
            "duration": 15,
            "extra": {
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {}
    },
    "RecentlyOvercharged": {
        "tooltip": {
            "name": "Recently Overcharged",
            "desc": "You were recently overcharged.",
            "icon": "flame-tunnel",
            "color": "#000"
        },
        "effect": {
            "type": "debuff",
            "duration": 15,
            "extra": {
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {}
    },
    "RecentlyHasted": {
        "tooltip": {
            "name": "Recently Hasted",
            "desc": "You were recently hasted.",
            "icon": "time-trap",
            "color": "#000"
        },
        "effect": {
            "type": "debuff",
            "duration": 60,
            "extra": {
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {}
    },
    "RecentlyDebilitated": {
        "tooltip": {
            "name": "Recently Debilitated",
            "desc": "You were recently debilitated.",
            "icon": "one-eyed",
            "color": "#000"
        },
        "effect": {
            "type": "debuff",
            "duration": 60,
            "extra": {
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {}
    },
    "RecentlyImmobilized": {
        "tooltip": {
            "name": "Recently Immobilized",
            "desc": "You were recently immobilized",
            "icon": "web-spit",
            "color": "#000"
        },
        "effect": {
            "type": "debuff",
            "duration": 10,
            "extra": {
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {}
    },
    "RecentlyFeared": {
        "tooltip": {
            "name": "Recently Feared",
            "desc": "You were recently feared.",
            "icon": "screaming",
            "color": "#000"
        },
        "effect": {
            "type": "debuff",
            "duration": 20,
            "extra": {
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {}
    },
    "EtherFire": {
        "tooltip": {
            "name": "Ether Fire",
            "desc": "Negate the resistances of undead-class monsters.",
            "icon": "pyromaniac",
            "color": "#700"
        },
        "effect": {
            "type": "buff",
            "duration": 3600,
            "extra": {
                "canRemove": true,
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {}
    },
    "BeastRipper": {
        "tooltip": {
            "name": "Beast Ripper",
            "desc": "Negate the resistances of beast-class monsters.",
            "icon": "bird-claw",
            "color": "#700"
        },
        "effect": {
            "type": "buff",
            "duration": 3600,
            "extra": {
                "canRemove": true,
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {}
    },
    "ScaleShredder": {
        "tooltip": {
            "name": "Scale Shredder",
            "desc": "Negate the resistances of dragon-class monsters.",
            "icon": "spiked-dragon-head",
            "color": "#700"
        },
        "effect": {
            "type": "buff",
            "duration": 3600,
            "extra": {
                "canRemove": true,
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {}
    },
    "Hidden": {
        "tooltip": {
            "desc": "Hidden in the shadows, ready to pounce.",
            "icon": "hidden",
            "color": "#ccc",
            "bgColor": "#000"
        },
        "effect": {
            "type": "buff",
            "duration": -1,
            "extra": {
                "canRemove": true,
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {
            "effectRef": "Hidden",
            "applyMessage": "You step into the shadows.",
            "unapplyMessage": "Your stealth is broken!"
        }
    },
    "ExactHeal": {
        "tooltip": {
            "name": "Exact Heal"
        },
        "effect": {
            "type": "useonly",
            "extra": {
                "potency": 1
            },
            "duration": 0
        },
        "effectMeta": {
            "effectRef": "ExactHeal"
        }
    },
    "ExactHealMP": {
        "tooltip": {
            "name": "Exact Heal MP"
        },
        "effect": {
            "type": "useonly",
            "extra": {
                "potency": 1
            },
            "duration": 0
        },
        "effectMeta": {
            "effectRef": "ExactHealMP"
        }
    },
    "ExactHealRegen": {
        "tooltip": {
            "name": "Exact Heal Regen"
        },
        "effect": {
            "type": "useonly",
            "extra": {
                "potency": 1
            },
            "duration": 0
        },
        "effectMeta": {
            "effectRef": "ExactHealRegen"
        }
    },
    "ChristmasCarrot": {
        "tooltip": {
            "name": "Christmas Carrot"
        },
        "effect": {
            "type": "useonly",
            "extra": {
                "potency": 1
            },
            "duration": 0
        },
        "effectMeta": {
            "effectRef": "ChristmasCarrot"
        }
    },
    "ThanksgivingLUK": {
        "tooltip": {
            "name": "Thanksgiving LUK"
        },
        "effect": {
            "type": "useonly",
            "extra": {
                "potency": 1
            },
            "duration": 0
        },
        "effectMeta": {
            "effectRef": "ThanksgivingLUK"
        }
    },
    "PermanentSTR": {
        "tooltip": {
            "name": "Permanent STR"
        },
        "effect": {
            "type": "useonly",
            "extra": {
                "potency": 1
            },
            "duration": 0
        },
        "effectMeta": {
            "effectRef": "PermanentSTR"
        }
    },
    "PermanentAGI": {
        "tooltip": {
            "name": "Permanent AGI"
        },
        "effect": {
            "type": "useonly",
            "extra": {
                "potency": 1
            },
            "duration": 0
        },
        "effectMeta": {
            "effectRef": "PermanentAGI"
        }
    },
    "PermanentDEX": {
        "tooltip": {
            "name": "Permanent DEX"
        },
        "effect": {
            "type": "useonly",
            "extra": {
                "potency": 1
            },
            "duration": 0
        },
        "effectMeta": {
            "effectRef": "PermanentDEX"
        }
    },
    "PermanentINT": {
        "tooltip": {
            "name": "Permanent INT"
        },
        "effect": {
            "type": "useonly",
            "extra": {
                "potency": 1
            },
            "duration": 0
        },
        "effectMeta": {
            "effectRef": "PermanentINT"
        }
    },
    "PermanentWIS": {
        "tooltip": {
            "name": "Permanent WIS"
        },
        "effect": {
            "type": "useonly",
            "extra": {
                "potency": 1
            },
            "duration": 0
        },
        "effectMeta": {
            "effectRef": "PermanentWIS"
        }
    },
    "PermanentWIL": {
        "tooltip": {
            "name": "Permanent WIL"
        },
        "effect": {
            "type": "useonly",
            "extra": {
                "potency": 1
            },
            "duration": 0
        },
        "effectMeta": {
            "effectRef": "PermanentWIL"
        }
    },
    "PermanentCON": {
        "tooltip": {
            "name": "Permanent CON"
        },
        "effect": {
            "type": "useonly",
            "extra": {
                "potency": 1
            },
            "duration": 0
        },
        "effectMeta": {
            "effectRef": "PermanentCON"
        }
    },
    "PermanentCHA": {
        "tooltip": {
            "name": "Permanent CHA"
        },
        "effect": {
            "type": "useonly",
            "extra": {
                "potency": 1
            },
            "duration": 0
        },
        "effectMeta": {
            "effectRef": "PermanentCHA"
        }
    },
    "PermanentLUK": {
        "tooltip": {
            "name": "Permanent LUK"
        },
        "effect": {
            "type": "useonly",
            "extra": {
                "potency": 1
            },
            "duration": 0
        },
        "effectMeta": {
            "effectRef": "PermanentLUK"
        }
    },
    "MinorHP": {
        "tooltip": {
            "name": "Minor HP"
        },
        "effect": {
            "type": "useonly",
            "extra": {
                "potency": 2
            },
            "duration": 0
        },
        "effectMeta": {
            "effectRef": "MinorHP"
        }
    },
    "MinorMP": {
        "tooltip": {
            "name": "Minor MP"
        },
        "effect": {
            "type": "useonly",
            "extra": {
                "potency": 2
            },
            "duration": 0
        },
        "effectMeta": {
            "effectRef": "MinorMP"
        }
    },
    "PermanentExperience": {
        "tooltip": {
            "name": "Permanent Experience"
        },
        "effect": {
            "type": "useonly",
            "extra": {
                "potency": 1
            },
            "duration": 0
        },
        "effectMeta": {
            "effectRef": "PermanentExperience"
        }
    },
    "PermanentSkill": {
        "tooltip": {
            "name": "Permanent Skill"
        },
        "effect": {
            "type": "useonly",
            "extra": {
                "potency": 1
            },
            "duration": 0
        },
        "effectMeta": {
            "effectRef": "PermanentSkill"
        }
    },
    "ImbueFrost": {
        "tooltip": {
            "name": "Imbue Frost",
            "color": "#00b",
            "bgColor": "",
            "desc": "Your attacks sometimes do additional %potency frost damage.",
            "icon": "magic-palm"
        },
        "effect": {
            "type": "outgoing",
            "duration": 600,
            "durationScaleStat": "int",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": "Imbue",
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": true,
                "charges": 0,
                "statChanges": {}
            }
        },
        "effectMeta": {
            "castSfx": "spell-buff-physical",
            "applySfx": "spell-buff-physical",
            "castMessage": "You cast Imbue Frost on %target.",
            "applyMessage": "A whirling blue aura envelops your hands.",
            "unapplyMessage": "The blue aura surrounding your hands fades."
        }
    },
    "ImbueFlame": {
        "tooltip": {
            "name": "Imbue Flame",
            "color": "#a00",
            "bgColor": "",
            "desc": "Your attacks sometimes do %potency additional fire damage.",
            "icon": "magic-palm"
        },
        "effect": {
            "type": "outgoing",
            "duration": 600,
            "durationScaleStat": "int",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": "Imbue",
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": true,
                "charges": 0,
                "statChanges": {}
            }
        },
        "effectMeta": {
            "castSfx": "spell-buff-physical",
            "applySfx": "spell-buff-physical",
            "castMessage": "You cast Imbue Flame on %target.",
            "applyMessage": "A whirling red aura envelops your hands.",
            "unapplyMessage": "The red aura surrounding your hands fades."
        }
    },
    "ImbueEnergy": {
        "tooltip": {
            "name": "Imbue Energy",
            "color": "#a0a",
            "bgColor": "",
            "desc": "Your attacks sometimes do %potency additional energy damage.",
            "icon": "magic-palm"
        },
        "effect": {
            "type": "outgoing",
            "duration": 600,
            "durationScaleStat": "int",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": "Imbue",
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": true,
                "charges": 0,
                "statChanges": {}
            }
        },
        "effectMeta": {
            "castSfx": "spell-buff-physical",
            "applySfx": "spell-buff-physical",
            "castMessage": "You cast Imbue Energy on %target.",
            "applyMessage": "A whirling purple aura envelops your hands.",
            "unapplyMessage": "The purple aura surrounding your hands fades."
        }
    },
    "DarkVision": {
        "tooltip": {
            "name": "Dark Vision",
            "color": "#000",
            "bgColor": "",
            "desc": "Seeing in the dark.",
            "icon": "angry-eyes"
        },
        "effect": {
            "type": "buff",
            "duration": 600,
            "durationScaleStat": "int",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {}
            }
        },
        "effectMeta": {
            "castSfx": "spell-sight-effect",
            "applySfx": "spell-sight-effect",
            "castMessage": "You cast DarkVision on %target.",
            "applyMessage": "You can see in the dark!",
            "unapplyMessage": "Your darkvision fades."
        }
    },
    "TrueSight": {
        "tooltip": {
            "name": "True Sight",
            "color": "#00a",
            "bgColor": "",
            "desc": "Seeing other planes of existence.",
            "icon": "all-seeing-eye"
        },
        "effect": {
            "type": "buff",
            "duration": 600,
            "durationScaleStat": "int",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {}
            }
        },
        "effectMeta": {
            "castSfx": "spell-sight-effect",
            "applySfx": "spell-sight-effect",
            "castMessage": "You cast TrueSight on %target.",
            "applyMessage": "Your vision expands to see other planes of existence!",
            "unapplyMessage": "Your vision returns to normal."
        }
    },
    "EagleEye": {
        "tooltip": {
            "name": "Eagle Eye",
            "color": "#f00",
            "bgColor": "",
            "desc": "Seeing through trees and water.",
            "icon": "dead-eye"
        },
        "effect": {
            "type": "buff",
            "duration": 600,
            "durationScaleStat": "int",
            "durationScaleValue": 25,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {}
            }
        },
        "effectMeta": {
            "castSfx": "spell-sight-effect",
            "applySfx": "spell-sight-effect",
            "castMessage": "You cast EagleEye on %target.",
            "applyMessage": "Your vision sharpens!",
            "unapplyMessage": "Your vision returns to normal."
        }
    },
    "SecondWind": {
        "tooltip": {
            "name": "Second Wind",
            "color": "#a0a",
            "bgColor": "",
            "desc": "Will not lose gear on death.",
            "icon": "wing-cloak"
        },
        "effect": {
            "type": "buff",
            "duration": 600,
            "durationScaleStat": "wis",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {}
            }
        },
        "effectMeta": {
            "castSfx": "spell-buff-magical",
            "applySfx": "spell-buff-magical",
            "castMessage": "You cast SecondWind on %target.",
            "applyMessage": "You are enclosed by a safe aura!",
            "unapplyMessage": "Your safety aura fades."
        }
    },
    "FleetOfFoot": {
        "tooltip": {
            "name": "Fleet Of Foot",
            "color": "#0aa",
            "bgColor": "",
            "desc": "Take less damage from falls and be immune to prone. Cost: 25 MP",
            "icon": "wingfoot"
        },
        "effect": {
            "type": "buff",
            "duration": 600,
            "durationScaleStat": "wis",
            "durationScaleValue": 150,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {}
            }
        },
        "effectMeta": {
            "castSfx": "spell-buff-physical",
            "applySfx": "spell-buff-physical",
            "castMessage": "You cast FleetOfFoot on %target.",
            "applyMessage": "You feel light on your feet!",
            "unapplyMessage": "Your body returns to the ground."
        }
    },
    "Invisibility": {
        "tooltip": {
            "name": "",
            "color": "#000",
            "bgColor": "",
            "desc": "You are invisible, most creatures will not be able to detect you.",
            "icon": "invisible"
        },
        "effect": {
            "type": "buff",
            "duration": 600,
            "durationScaleStat": "int",
            "durationScaleValue": 15,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {}
            }
        },
        "effectMeta": {
            "castSfx": "spell-sight-effect",
            "applySfx": "spell-sight-effect",
            "castMessage": "You cast Invisibility on %target.",
            "applyMessage": "You can see through yourself!",
            "unapplyMessage": "You become opaque again."
        }
    },
    "BarFire": {
        "tooltip": {
            "name": "Bar Fire",
            "color": "#dc143c",
            "bgColor": "",
            "desc": "Resisting %potency fire damage.",
            "icon": "rosa-shield"
        },
        "effect": {
            "type": "buff",
            "duration": 600,
            "durationScaleStat": "int",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {},
                "spriteChange": -1
            }
        },
        "effectMeta": {
            "castSfx": "spell-buff-magical",
            "applySfx": "spell-buff-magical",
            "castMessage": "You cast BarFire on %target.",
            "applyMessage": "Your body builds a temporary resistance to heat!",
            "unapplyMessage": "Your heat resistance fades."
        }
    },
    "BarFrost": {
        "tooltip": {
            "name": "Bar Frost",
            "color": "#000080",
            "bgColor": "",
            "desc": "Resisting %potency ice damage.",
            "icon": "rosa-shield"
        },
        "effect": {
            "type": "buff",
            "duration": 600,
            "durationScaleStat": "int",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {},
                "spriteChange": -1
            }
        },
        "effectMeta": {
            "castSfx": "spell-buff-magical",
            "applySfx": "spell-buff-magical",
            "castMessage": "You cast BarFrost on %target.",
            "applyMessage": "Your body builds a temporary resistance to cold!",
            "unapplyMessage": "Your cold resistance fades."
        }
    },
    "BarWater": {
        "tooltip": {
            "name": "Bar Water",
            "color": "#208aec",
            "bgColor": "",
            "desc": "Resisting %potency water damage (including swimming).",
            "icon": "rosa-shield"
        },
        "effect": {
            "type": "buff",
            "duration": 600,
            "durationScaleStat": "int",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {},
                "spriteChange": -1
            }
        },
        "effectMeta": {
            "castSfx": "spell-buff-magical",
            "applySfx": "spell-buff-magical",
            "castMessage": "You cast BarWater on %target.",
            "applyMessage": "Your body become able to handle water better!",
            "unapplyMessage": "Your water capabilities fade."
        }
    },
    "BarNecro": {
        "tooltip": {
            "name": "Bar Necro",
            "color": "#0a0",
            "bgColor": "",
            "desc": "Resisting %potency necrotic damage, %potency5 poison damage, and %potency10 disease damage.",
            "icon": "rosa-shield"
        },
        "effect": {
            "type": "buff",
            "duration": 600,
            "durationScaleStat": "int",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {},
                "spriteChange": -1
            }
        },
        "effectMeta": {
            "castSfx": "spell-buff-magical",
            "applySfx": "spell-buff-magical",
            "castMessage": "You cast BarNecro on %target.",
            "applyMessage": "Your body builds a temporary resistance to the dark arts!",
            "unapplyMessage": "Your resistance to the dark arts fades."
        }
    },
    "Aid": {
        "tooltip": {
            "name": "",
            "color": "#00c",
            "bgColor": "",
            "desc": "Increase DEX by %potency5 and Offense by %potency.",
            "icon": "three-friends"
        },
        "effect": {
            "type": "buff",
            "duration": 600,
            "durationScaleStat": "wis",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {}
            }
        },
        "effectMeta": {
            "castSfx": "spell-buff-magical",
            "applySfx": "spell-buff-magical",
            "castMessage": "You cast Aid on %target.",
            "applyMessage": "You feel guided by a higher presence!",
            "unapplyMessage": "The guidance fades."
        }
    },
    "Regen": {
        "tooltip": {
            "name": "",
            "color": "#00a",
            "bgColor": "",
            "desc": "Regenerating %potency health per tick.",
            "icon": "star-swirl"
        },
        "effect": {
            "type": "buff",
            "duration": 0,
            "durationScaleStat": "wis",
            "durationScaleValue": 1,
            "extra": {
                "potency": 0,
                "canRemove": false,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {}
            }
        },
        "effectMeta": {
            "effectRef": "Regen",
            "castSfx": "spell-buff-give",
            "applySfx": "spell-buff-magical",
            "castMessage": "You gave regeneration to %target.",
            "applyMessage": "You are now regenerating health!",
            "unapplyMessage": "You are no longer regenerating health."
        }
    },
    "Protection": {
        "tooltip": {
            "name": "",
            "color": "#a0a",
            "bgColor": "",
            "desc": "Resisting %potency physical damage.",
            "icon": "energy-shield"
        },
        "effect": {
            "type": "buff",
            "duration": 600,
            "durationScaleStat": "int",
            "durationScaleValue": 50,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {}
            }
        },
        "effectMeta": {
            "castSfx": "spell-buff-magical",
            "applySfx": "spell-buff-magical",
            "castMessage": "You cast Protection on %target.",
            "applyMessage": "You begin to glow light blue!",
            "unapplyMessage": "Your light blue glow fades."
        }
    },
    "Absorption": {
        "tooltip": {
            "name": "",
            "color": "#a0a",
            "bgColor": "",
            "desc": "Resisting %potency magical damage.",
            "icon": "magic-swirl"
        },
        "effect": {
            "type": "buff",
            "duration": 600,
            "durationScaleStat": "int",
            "durationScaleValue": 50,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {}
            }
        },
        "effectMeta": {
            "castSfx": "spell-buff-magical",
            "applySfx": "spell-buff-magical",
            "castMessage": "You cast Absorption on %target.",
            "applyMessage": "You begin to glow dark purple!",
            "unapplyMessage": "Your dark purple glow fades."
        }
    },
    "Haste": {
        "tooltip": {
            "name": "",
            "color": "#0a0",
            "bgColor": "",
            "desc": "Acting twice as fast.",
            "icon": "time-trap"
        },
        "effect": {
            "type": "buff",
            "duration": 600,
            "durationScaleStat": "int",
            "durationScaleValue": 25,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {}
            }
        },
        "effectMeta": {
            "recentlyRef": "RecentlyHasted",
            "castSfx": "spell-buff-magical",
            "applySfx": "spell-buff-magical",
            "castMessage": "You cast Haste on %target.",
            "applyMessage": "The world around you begins to slow down!",
            "unapplyMessage": "The world around you speeds up again."
        }
    },
    "VitalEssence": {
        "tooltip": {
            "name": "Vital Essence",
            "color": "#0a0",
            "bgColor": "",
            "desc": "Increased HP and AC.",
            "icon": "bell-shield"
        },
        "effect": {
            "type": "incoming",
            "duration": -1,
            "durationScaleStat": "wis",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 600,
                "statChanges": {},
                "spriteChange": -1
            }
        },
        "effectMeta": {
            "castSfx": "spell-buff-magical",
            "applySfx": "spell-buff-magical",
            "castMessage": "You cast VitalEssence on %target.",
            "applyMessage": "Your body feels more durable!",
            "unapplyMessage": "The durability fades."
        }
    },
    "HolyAura": {
        "tooltip": {
            "name": "Holy Aura",
            "color": "#aa0",
            "bgColor": "",
            "desc": "You are resisting some physical damage.",
            "icon": "aura"
        },
        "effect": {
            "type": "incoming",
            "duration": 30,
            "durationScaleStat": "wis",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {}
            }
        },
        "effectMeta": {
            "effectRef": "HolyAura",
            "recentlyRef": "RecentlyShielded",
            "castSfx": "spell-buff-give",
            "applySfx": "spell-buff-magical",
            "castMessage": "You cast HolyAura on %target.",
            "applyMessage": "You're glowing with strength!",
            "unapplyMessage": "You are no longer glowing."
        }
    },
    "Autoheal": {
        "tooltip": {
            "name": "",
            "color": "#00c",
            "bgColor": "",
            "desc": "Automatically heal when your HP gets low.",
            "icon": "self-love"
        },
        "effect": {
            "type": "incoming",
            "duration": 100,
            "durationScaleStat": "wis",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {}
            }
        },
        "effectMeta": {
            "castSfx": "spell-buff-magical",
            "applySfx": "spell-buff-magical",
            "castMessage": "You cast AutoHeal on %target.",
            "applyMessage": "Your chest feels warm!",
            "unapplyMessage": "The warmth in your chest fades."
        }
    },
    "MagicMirror": {
        "tooltip": {
            "name": "Magic Mirror",
            "color": "#a0a",
            "bgColor": "",
            "desc": "Reflecting magic %potency% of the time.",
            "icon": "mirror-mirror"
        },
        "effect": {
            "type": "buff",
            "duration": 600,
            "durationScaleStat": "int",
            "durationScaleValue": 50,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {}
            }
        },
        "effectMeta": {
            "castSfx": "spell-buff-magical",
            "applySfx": "spell-buff-magical",
            "castMessage": "You cast Magic Mirror on %target.",
            "applyMessage": "Your skin begins to reflect the light around you!",
            "unapplyMessage": "Your reflective skin fades."
        }
    },
    "FrostspikesAura": {
        "tooltip": {
            "name": "Frostspikes Aura",
            "color": "#000080",
            "bgColor": "",
            "desc": "You are reflecting %potency physical damage and countering with ice.",
            "icon": "barbed-coil"
        },
        "effect": {
            "type": "incoming",
            "duration": 600,
            "durationScaleStat": "int",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": "Aura",
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {}
            }
        },
        "effectMeta": {
            "effectRef": "FrostspikesAura",
            "castSfx": "spell-buff-give",
            "applySfx": "spell-buff-magical",
            "castMessage": "You cast Frostspikes on %target.",
            "applyMessage": "A spiky blue aura appears around you!",
            "unapplyMessage": "Your spiky blue aura fades."
        }
    },
    "FirethornsAura": {
        "tooltip": {
            "name": "Firethorns Aura",
            "color": "#f00",
            "bgColor": "",
            "desc": "You are reflecting %potency physical damage and countering with fire.",
            "icon": "barbed-coil"
        },
        "effect": {
            "type": "incoming",
            "duration": 600,
            "durationScaleStat": "int",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": "Aura",
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {}
            }
        },
        "effectMeta": {
            "effectRef": "FirethornsAura",
            "castSfx": "spell-buff-give",
            "applySfx": "spell-buff-magical",
            "castMessage": "You cast Firethorns on %target.",
            "applyMessage": "A thorny red aura appears around you!",
            "unapplyMessage": "Your thorny red aura fades."
        }
    },
    "Lifepool": {
        "tooltip": {
            "name": "",
            "color": "#f00",
            "bgColor": "",
            "desc": "+%potency max HP.",
            "icon": "ball-glow"
        },
        "effect": {
            "type": "buff",
            "duration": 600,
            "durationScaleStat": "wis",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": "pool",
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {}
            }
        },
        "effectMeta": {
            "effectRef": "Lifepool",
            "castSfx": "spell-buff-give",
            "applySfx": "spell-buff-magical",
            "castMessage": "You cast Lifepool on %target.",
            "applyMessage": "Your chest begins to glow bright red!",
            "unapplyMessage": "Your red glow fades."
        }
    },
    "Manapool": {
        "tooltip": {
            "name": "",
            "color": "#00f",
            "bgColor": "",
            "desc": "+%potency max MP.",
            "icon": "ball-glow"
        },
        "effect": {
            "type": "buff",
            "duration": 600,
            "durationScaleStat": "wis",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": "pool",
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {}
            }
        },
        "effectMeta": {
            "effectRef": "Manapool",
            "castSfx": "spell-buff-give",
            "applySfx": "spell-buff-magical",
            "castMessage": "You cast Manapool on %target.",
            "applyMessage": "Your chest begins to glow bright blue!",
            "unapplyMessage": "Your blue glow fades."
        }
    },
    "Barkskin": {
        "tooltip": {
            "name": "",
            "color": "#964b00",
            "bgColor": "",
            "desc": "Armor class increased by %potency.",
            "icon": "tree-branch"
        },
        "effect": {
            "type": "buff",
            "duration": 600,
            "durationScaleStat": "wis",
            "durationScaleValue": 50,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": "skin",
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {}
            }
        },
        "effectMeta": {
            "effectRef": "Barkskin",
            "castSfx": "spell-buff-give",
            "applySfx": "spell-buff-magical",
            "castMessage": "You cast Barkskin on %target.",
            "applyMessage": "Your skin hardens like a tree!",
            "unapplyMessage": "Your skin returns to normal."
        }
    },
    "BulgingMuscles": {
        "tooltip": {
            "name": "Bulging Muscles",
            "color": "#f00",
            "bgColor": "",
            "desc": "Physical damage increased by %potency.",
            "icon": "strong"
        },
        "effect": {
            "type": "buff",
            "duration": 600,
            "durationScaleStat": "wis",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {}
            }
        },
        "effectMeta": {
            "effectRef": "BulgingMuscles",
            "castSfx": "spell-buff-give",
            "applySfx": "spell-buff-magical",
            "castMessage": "You cast Bulging Muscles on %target.",
            "applyMessage": "Your muscles bulge unnaturally!",
            "unapplyMessage": "Your muscles return to normal."
        }
    },
    "Spellshield": {
        "tooltip": {
            "name": "",
            "color": "#f0f",
            "bgColor": "",
            "desc": "Saving throws increased by %potency.",
            "icon": "shield-echoes"
        },
        "effect": {
            "type": "buff",
            "duration": 600,
            "durationScaleStat": "int",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {},
                "spriteChange": -1
            }
        },
        "effectMeta": {
            "effectRef": "Spellshield",
            "castSfx": "spell-buff-give",
            "applySfx": "spell-buff-magical",
            "castMessage": "You cast Spellshield on %target.",
            "applyMessage": "You feel resistant to magic!",
            "unapplyMessage": "Your magic resistance fades."
        }
    },
    "DeftStrikes": {
        "tooltip": {
            "name": "Deft Strikes",
            "color": "#f00",
            "bgColor": "",
            "desc": "Weapon damage rolls increased by %potency.",
            "icon": "sword-brandish"
        },
        "effect": {
            "type": "buff",
            "duration": 600,
            "durationScaleStat": "wis",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {}
            }
        },
        "effectMeta": {
            "effectRef": "DeftStrikes",
            "castSfx": "spell-buff-give",
            "applySfx": "spell-buff-magical",
            "castMessage": "You cast Deft Strikes on %target.",
            "applyMessage": "Your weapon feels lighter!",
            "unapplyMessage": "Your weapons weight returns to normal."
        }
    },
    "CombatPower": {
        "tooltip": {
            "name": "Combat Power",
            "color": "#f00",
            "bgColor": "",
            "desc": "Offense/Defense increased by %potency.",
            "icon": "swords-emblem"
        },
        "effect": {
            "type": "buff",
            "duration": 600,
            "durationScaleStat": "wis",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {}
            }
        },
        "effectMeta": {
            "effectRef": "CombatPower",
            "castSfx": "spell-buff-give",
            "applySfx": "spell-buff-magical",
            "castMessage": "You cast Combat Power on %target.",
            "applyMessage": "You feel more combat-ready!",
            "unapplyMessage": "Your readiness fades."
        }
    },
    "ThreateningAura": {
        "tooltip": {
            "name": "Threatening Aura",
            "color": "#f00",
            "bgColor": "",
            "desc": "Threat multiplier increased by %potency.",
            "icon": "aura"
        },
        "effect": {
            "type": "buff",
            "duration": 600,
            "durationScaleStat": "wis",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {}
            }
        },
        "effectMeta": {
            "effectRef": "ThreateningAura",
            "castSfx": "spell-buff-give",
            "applySfx": "spell-buff-magical",
            "castMessage": "You cast Threatening Aura on %target.",
            "applyMessage": "You feel more threatening!",
            "unapplyMessage": "Your threatening aura fades."
        }
    },
    "Poison": {
        "tooltip": {
            "name": "",
            "color": "#0a0",
            "bgColor": "",
            "desc": "Taking %potency poison damage per tick.",
            "icon": "poison-gas"
        },
        "effect": {
            "type": "debuff",
            "duration": 10,
            "durationScaleStat": "wis",
            "durationScaleValue": 3,
            "extra": {
                "potency": 0,
                "canRemove": false,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {}
            }
        },
        "effectMeta": {
            "effectRef": "Poison",
            "castSfx": "spell-debuff-give",
            "applySfx": "spell-debuff-receive",
            "castMessage": "You poisoned %target.",
            "applyMessage": "You've been poisoned!",
            "unapplyMessage": "You are no longer poisoned."
        }
    },
    "Venom": {
        "tooltip": {
            "name": "",
            "color": "#0a0",
            "bgColor": "",
            "desc": "Taking %potency poison damage per tick.",
            "icon": "dripping-goo"
        },
        "effect": {
            "type": "debuff",
            "duration": 10,
            "durationScaleStat": "dex",
            "durationScaleValue": 3,
            "extra": {
                "potency": 0,
                "canRemove": false,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {}
            }
        },
        "effectMeta": {
            "effectRef": "Venom",
            "castSfx": "spell-debuff-give",
            "applySfx": "spell-debuff-receive",
            "castMessage": "You cast a deadly venom on %target.",
            "applyMessage": "You feel a deadly venom coursing through your veins!",
            "unapplyMessage": "You are no longer envenomed."
        }
    },
    "Disease": {
        "tooltip": {
            "name": "",
            "color": "#0c0",
            "bgColor": "",
            "desc": "Taking %potency disease damage per tick.",
            "icon": "death-juice"
        },
        "effect": {
            "type": "debuff",
            "duration": 10,
            "durationScaleStat": "wis",
            "durationScaleValue": 2,
            "extra": {
                "potency": 0,
                "canRemove": false,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {}
            }
        },
        "effectMeta": {
            "effectRef": "Disease",
            "castSfx": "spell-debuff-give",
            "applySfx": "spell-debuff-receive",
            "castMessage": "You diseased %target.",
            "applyMessage": "You've been diseased!",
            "unapplyMessage": "You are no longer diseased."
        }
    },
    "Plague": {
        "tooltip": {
            "name": "",
            "color": "#0c0",
            "bgColor": "",
            "desc": "Taking %potency disease damage per tick.",
            "icon": "death-zone"
        },
        "effect": {
            "type": "debuff",
            "duration": 10,
            "durationScaleStat": "wis",
            "durationScaleValue": 1,
            "extra": {
                "potency": 0,
                "canRemove": false,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {}
            }
        },
        "effectMeta": {
            "effectRef": "Plague",
            "castSfx": "spell-debuff-give",
            "applySfx": "spell-debuff-receive",
            "castMessage": "You plagued %target.",
            "applyMessage": "You've been plagued!",
            "unapplyMessage": "You are no longer plagued."
        }
    },
    "Stun": {
        "tooltip": {
            "name": "",
            "color": "#990",
            "bgColor": "",
            "desc": "You are stunned, and cannot act.",
            "icon": "knockout"
        },
        "effect": {
            "type": "debuff",
            "duration": 0,
            "extra": {
                "potency": 0,
                "canRemove": false,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {}
            }
        },
        "effectMeta": {
            "effectRef": "Stun",
            "recentlyRef": "RecentlyStunned",
            "noStack": true,
            "castSfx": "spell-debuff-give",
            "applySfx": "spell-debuff-receive",
            "castMessage": "You stunned %target.",
            "applyMessage": "You've been stunned!",
            "unapplyMessage": "You are no longer stunned."
        }
    },
    "Snare": {
        "tooltip": {
            "name": "",
            "color": "#0a0",
            "bgColor": "",
            "desc": "Snared. -%potency MOVE.",
            "icon": "light-thorny-triskelion"
        },
        "effect": {
            "type": "debuff",
            "duration": 5,
            "durationScaleStat": "wis",
            "durationScaleValue": 0.5,
            "extra": {
                "potency": 0,
                "canRemove": false,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {},
                "spriteChange": -1
            }
        },
        "effectMeta": {
            "effectRef": "Snare",
            "recentlyRef": "RecentlySnared",
            "castSfx": "spell-debuff-give",
            "applySfx": "spell-debuff-receive",
            "castMessage": "You snared %target.",
            "applyMessage": "You've been snared!",
            "unapplyMessage": "You are no longer snared."
        }
    },
    "Daze": {
        "tooltip": {
            "name": "",
            "color": "#0aa",
            "bgColor": "",
            "desc": "Dazed. Failing spellcasts %potency% of the time.",
            "icon": "knockout"
        },
        "effect": {
            "type": "debuff",
            "duration": 0,
            "durationScaleStat": "int",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": false,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {},
                "spriteChange": -1
            }
        },
        "effectMeta": {
            "effectRef": "Daze",
            "recentlyRef": "RecentlyDazed",
            "castSfx": "spell-debuff-give",
            "applySfx": "spell-debuff-receive",
            "castMessage": "You dazed %target.",
            "applyMessage": "You've been dazed!",
            "unapplyMessage": "You are no longer dazed."
        }
    },
    "Blind": {
        "tooltip": {
            "name": "",
            "color": "#900",
            "bgColor": "",
            "desc": "You are blinded and cannot see.",
            "icon": "evil-eyes"
        },
        "effect": {
            "type": "debuff",
            "duration": 0,
            "extra": {
                "potency": 0,
                "canRemove": false,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {}
            }
        },
        "effectMeta": {
            "effectRef": "Blind",
            "recentlyRef": "RecentlyBlinded",
            "castSfx": "spell-debuff-give",
            "applySfx": "spell-debuff-receive",
            "castMessage": "You blinded %target.",
            "applyMessage": "You've been blinded!",
            "unapplyMessage": "You are no longer blinded."
        }
    },
    "Debilitate": {
        "tooltip": {
            "name": "",
            "color": "#a00",
            "bgColor": "",
            "desc": "All attacks count as backstabs.",
            "icon": "one-eyed"
        },
        "effect": {
            "type": "debuff",
            "duration": 15,
            "extra": {
                "potency": 0,
                "canRemove": false,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {}
            }
        },
        "effectMeta": {
            "recentlyRef": "RecentlyDebilitated",
            "castSfx": "spell-debuff-physical",
            "applySfx": "spell-debuff-physical",
            "castMessage": "You cast Debilitate on %target.",
            "applyMessage": "You see daggers in every corner of your eyes!",
            "unapplyMessage": "Your paranoia fades."
        }
    },
    "Fear": {
        "tooltip": {
            "name": "",
            "color": "#a00",
            "bgColor": "",
            "desc": "Fleeing in terror.",
            "icon": "screaming"
        },
        "effect": {
            "type": "debuff",
            "duration": 10,
            "extra": {
                "potency": 0,
                "canRemove": false,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {}
            }
        },
        "effectMeta": {
            "recentlyRef": "RecentlyFeared",
            "castSfx": "spell-debuff-magical",
            "applySfx": "spell-debuff-magical",
            "castMessage": "You cast Fear on %target.",
            "applyMessage": "You are terrified!",
            "unapplyMessage": "You gain your resolve back."
        }
    },
    "PowerBalladSong": {
        "tooltip": {
            "name": "Power Ballad",
            "color": "#f00",
            "bgColor": "",
            "desc": "Song. +%potency STR/INT/WIS to nearby allies.",
            "icon": "music-spell"
        },
        "effect": {
            "type": "outgoing",
            "duration": -1,
            "durationScaleStat": null,
            "durationScaleValue": -1,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": "Song",
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {},
                "spriteChange": -1
            }
        },
        "effectMeta": {
            "effectRef": "PowerBalladSong"
        }
    },
    "WistfulFugueSong": {
        "tooltip": {
            "name": "Wistful Fugue",
            "color": "#00f",
            "bgColor": "",
            "desc": "Song. -%potency AGI/DEX/WIL to nearby enemies.",
            "icon": "music-spell"
        },
        "effect": {
            "type": "outgoing",
            "duration": -1,
            "durationScaleStat": null,
            "durationScaleValue": -1,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": "Song",
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {},
                "spriteChange": -1
            }
        },
        "effectMeta": {
            "effectRef": "WistfulFugueSong"
        }
    },
    "DeadlyDirgeSong": {
        "tooltip": {
            "name": "Deadly Dirge",
            "color": "#808",
            "bgColor": "",
            "desc": "Song. Damaging nearby enemies for %potency sonic damage every tick.",
            "icon": "music-spell"
        },
        "effect": {
            "type": "outgoing",
            "duration": -1,
            "durationScaleStat": null,
            "durationScaleValue": -1,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": "Song",
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {},
                "spriteChange": -1
            }
        },
        "effectMeta": {
            "effectRef": "DeadlyDirgeSong"
        }
    },
    "TranquilTrillSong": {
        "tooltip": {
            "name": "Tranquil Trill",
            "color": "#080",
            "bgColor": "",
            "desc": "Song. Healing nearby allies for %potency health every tick.",
            "icon": "music-spell"
        },
        "effect": {
            "type": "outgoing",
            "duration": -1,
            "durationScaleStat": null,
            "durationScaleValue": -1,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": "Song",
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {},
                "spriteChange": -1
            }
        },
        "effectMeta": {
            "effectRef": "TranquilTrillSong"
        }
    },
    "AriaOfRefugeSong": {
        "tooltip": {
            "name": "Aria Of Refuge",
            "color": "#aa0",
            "bgColor": "",
            "desc": "Song. +%potency physical and magical resist.",
            "icon": "music-spell"
        },
        "effect": {
            "type": "outgoing",
            "duration": -1,
            "durationScaleStat": null,
            "durationScaleValue": -1,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": "Song",
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {},
                "spriteChange": -1
            }
        },
        "effectMeta": {
            "effectRef": "AriaOfRefugeSong"
        }
    },
    "NightmareSerenadeSong": {
        "tooltip": {
            "name": "Nightmare Serenade",
            "color": "#0aa",
            "bgColor": "",
            "desc": "Song. -%potency physical/magical resist to nearby enemies.",
            "icon": "music-spell"
        },
        "effect": {
            "type": "outgoing",
            "duration": -1,
            "durationScaleStat": null,
            "durationScaleValue": -1,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": "Song",
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {},
                "spriteChange": -1
            }
        },
        "effectMeta": {
            "effectRef": "NightmareSerenadeSong"
        }
    },
    "VolcanoStance": {
        "tooltip": {
            "name": "Volcano Stance",
            "color": "#dc143c",
            "bgColor": "",
            "desc": "Stance. Acting offensively.",
            "icon": "fire-silhouette"
        },
        "effect": {
            "type": "outgoing",
            "duration": -1,
            "durationScaleStat": null,
            "durationScaleValue": -1,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": "Stance",
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {}
            }
        },
        "effectMeta": {}
    },
    "GlacierStance": {
        "tooltip": {
            "name": "Glacier Stance",
            "color": "#000080",
            "bgColor": "",
            "desc": "Stance. Acting defensively.",
            "icon": "frozen-orb"
        },
        "effect": {
            "type": "incoming",
            "duration": -1,
            "durationScaleStat": null,
            "durationScaleValue": -1,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": "Stance",
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {}
            }
        },
        "effectMeta": {}
    },
    "WizardStance": {
        "tooltip": {
            "name": "Wizard Stance",
            "color": "#a0a",
            "bgColor": "",
            "desc": "Stance. Acting wizardly.",
            "icon": "burning-passion"
        },
        "effect": {
            "type": "outgoing",
            "duration": -1,
            "durationScaleStat": null,
            "durationScaleValue": -1,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": "Stance",
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {}
            }
        },
        "effectMeta": {}
    },
    "ParryStance": {
        "tooltip": {
            "name": "Parry Stance",
            "color": "#000",
            "bgColor": "",
            "desc": "Stance. Acting defensively.",
            "icon": "surrounded-shield"
        },
        "effect": {
            "type": "incoming",
            "duration": -1,
            "durationScaleStat": null,
            "durationScaleValue": -1,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": "Stance",
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {}
            }
        },
        "effectMeta": {
            "effectRef": "ParryStance"
        }
    },
    "RageStance": {
        "tooltip": {
            "name": "Rage Stance",
            "color": "#000",
            "bgColor": "",
            "desc": "Stance. Acting offensively.",
            "icon": "swords-power"
        },
        "effect": {
            "type": "outgoing",
            "duration": -1,
            "durationScaleStat": null,
            "durationScaleValue": -1,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": "Stance",
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {}
            }
        },
        "effectMeta": {
            "effectRef": "RageStance"
        }
    },
    "TurtleStance": {
        "tooltip": {
            "name": "Turtle Stance",
            "color": "#000",
            "bgColor": "",
            "desc": "Stance. Acting defensively.",
            "icon": "turtle-shell"
        },
        "effect": {
            "type": "incoming",
            "duration": -1,
            "durationScaleStat": null,
            "durationScaleValue": -1,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": "Stance",
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {}
            }
        },
        "effectMeta": {
            "effectRef": "TurtleStance"
        }
    },
    "TigerStance": {
        "tooltip": {
            "name": "Tiger Stance",
            "color": "#000",
            "bgColor": "",
            "desc": "Stance. Acting offensively.",
            "icon": "tiger-head"
        },
        "effect": {
            "type": "incoming",
            "duration": -1,
            "durationScaleStat": null,
            "durationScaleValue": -1,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": "Stance",
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {}
            }
        },
        "effectMeta": {
            "effectRef": "TigerStance"
        }
    },
    "FindFamiliarLight": {
        "tooltip": {
            "name": "Find Familiar Light",
            "color": "#000",
            "bgColor": "",
            "desc": "Light spirit heals allies and grants +2 INT",
            "icon": "double-ringed-orb"
        },
        "effect": {
            "type": "buff",
            "duration": 0,
            "durationScaleStat": "int",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": false,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {
                    "int": 2
                },
                "spriteChange": -1
            }
        },
        "effectMeta": {
            "effectRef": "FindFamiliar",
            "castSfx": "spell-conjure",
            "applySfx": "spell-conjure",
            "unapplyMessage": "Your light familiar has returned to the ether."
        }
    },
    "FindFamiliarNature": {
        "tooltip": {
            "name": "Find Familiar Nature",
            "color": "#000",
            "bgColor": "",
            "desc": "Nature spirit debuffs enemies and grants +2 DEX",
            "icon": "tree-branch"
        },
        "effect": {
            "type": "buff",
            "duration": 0,
            "durationScaleStat": "int",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": false,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {
                    "dex": 2
                },
                "spriteChange": -1
            }
        },
        "effectMeta": {
            "effectRef": "FindFamiliar",
            "castSfx": "spell-conjure",
            "applySfx": "spell-conjure",
            "unapplyMessage": "Your nature familiar has returned to the ether."
        }
    },
    "FindFamiliarWater": {
        "tooltip": {
            "name": "Find Familiar Water",
            "color": "#000",
            "bgColor": "",
            "desc": "Water spirit buffs allies and grants +2 WIS",
            "icon": "water-splash"
        },
        "effect": {
            "type": "buff",
            "duration": 0,
            "durationScaleStat": "int",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": false,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {
                    "wis": 2
                },
                "spriteChange": -1
            }
        },
        "effectMeta": {
            "effectRef": "FindFamiliar",
            "castSfx": "spell-conjure",
            "applySfx": "spell-conjure",
            "unapplyMessage": "Your water familiar has returned to the ether."
        }
    },
    "FindFamiliarSpider": {
        "tooltip": {
            "name": "Find Familiar Spider",
            "color": "#000",
            "bgColor": "",
            "desc": "Chillspider fights enemies for you with frost techniques.",
            "icon": "spider-alt"
        },
        "effect": {
            "type": "buff",
            "duration": 0,
            "durationScaleStat": "int",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": false,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {},
                "spriteChange": -1
            }
        },
        "effectMeta": {
            "effectRef": "FindFamiliar",
            "castSfx": "spell-conjure",
            "applySfx": "spell-conjure",
            "unapplyMessage": "Your chillspider familiar has returned to the ether."
        }
    },
    "FindFamiliarSalamander": {
        "tooltip": {
            "name": "Find Familiar Salamander",
            "color": "#000",
            "bgColor": "",
            "desc": "Salamander fights enemies for you with fire techniques.",
            "icon": "salamander"
        },
        "effect": {
            "type": "buff",
            "duration": 0,
            "durationScaleStat": "int",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": false,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {},
                "spriteChange": -1
            }
        },
        "effectMeta": {
            "effectRef": "FindFamiliar",
            "castSfx": "spell-conjure",
            "applySfx": "spell-conjure",
            "unapplyMessage": "Your salamander familiar has returned to the ether."
        }
    },
    "FindFamiliarBear": {
        "tooltip": {
            "name": "Find Familiar Bear",
            "color": "#000",
            "bgColor": "",
            "desc": "Bear supports you as an agro tank.",
            "icon": "polar-bear"
        },
        "effect": {
            "type": "buff",
            "duration": 0,
            "durationScaleStat": "int",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": false,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {},
                "spriteChange": -1
            }
        },
        "effectMeta": {
            "effectRef": "FindFamiliar",
            "castSfx": "spell-conjure",
            "applySfx": "spell-conjure",
            "unapplyMessage": "Your bear familiar has returned to the ether."
        }
    },
    "FindFamiliarWolf": {
        "tooltip": {
            "name": "Find Familiar Wolf",
            "color": "#000",
            "bgColor": "",
            "desc": "Wolf supports you as a melee DPS.",
            "icon": "wolf-head"
        },
        "effect": {
            "type": "buff",
            "duration": 0,
            "durationScaleStat": "int",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": false,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {},
                "spriteChange": -1
            }
        },
        "effectMeta": {
            "effectRef": "FindFamiliar",
            "castSfx": "spell-conjure",
            "applySfx": "spell-conjure",
            "unapplyMessage": "Your wolf familiar has returned to the ether."
        }
    },
    "FindFamiliarShadowClones": {
        "tooltip": {
            "name": "Shadow Clones",
            "color": "#000",
            "bgColor": "",
            "desc": "Shadow clones attack enemies",
            "icon": "dark-squad"
        },
        "effect": {
            "type": "buff",
            "duration": 0,
            "durationScaleStat": "int",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": false,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {},
                "spriteChange": -1
            }
        },
        "effectMeta": {
            "effectRef": "FindFamiliar",
            "castSfx": "spell-conjure",
            "applySfx": "spell-conjure",
            "unapplyMessage": "Your shadow clone has returned to the darkness."
        }
    },
    "FindFamiliarDistraction": {
        "tooltip": {
            "name": "Distraction",
            "color": "#000",
            "bgColor": "",
            "desc": "Shadow distractions confuse enemies.",
            "icon": "dark-squad"
        },
        "effect": {
            "type": "buff",
            "duration": 0,
            "durationScaleStat": "int",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": false,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {},
                "spriteChange": -1
            }
        },
        "effectMeta": {
            "effectRef": "FindFamiliar",
            "castSfx": "spell-conjure",
            "applySfx": "spell-conjure",
            "unapplyMessage": "Your distraction has returned to the darkness."
        }
    },
    "FindFamiliarSnowman": {
        "tooltip": {
            "name": "Find Familiar Snowman",
            "color": "#000",
            "bgColor": "",
            "desc": "Snowman supports you using frost techniques.",
            "icon": "snowman"
        },
        "effect": {
            "type": "buff",
            "duration": 0,
            "durationScaleStat": "int",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": false,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {},
                "spriteChange": -1
            }
        },
        "effectMeta": {
            "effectRef": "FindFamiliar",
            "castSfx": "spell-conjure",
            "applySfx": "spell-conjure",
            "unapplyMessage": "Your snowman familiar has returned to the ether."
        }
    },
    "FindFamiliarTurkey": {
        "tooltip": {
            "name": "Find Familiar Turkey",
            "color": "#000",
            "bgColor": "",
            "desc": "Turkey supports you as a melee DPS.",
            "icon": "bird-twitter"
        },
        "effect": {
            "type": "buff",
            "duration": 0,
            "durationScaleStat": "int",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": false,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {},
                "spriteChange": -1
            }
        },
        "effectMeta": {
            "effectRef": "FindFamiliar",
            "castSfx": "spell-conjure",
            "applySfx": "spell-conjure",
            "unapplyMessage": "Your turkey familiar has returned to the ether."
        }
    },
    "FindFamiliarGolem": {
        "tooltip": {
            "name": "Find Familiar Golem",
            "color": "#000",
            "bgColor": "",
            "desc": "Golem supports you as a tank/melee DPS.",
            "icon": "rock-2"
        },
        "effect": {
            "type": "buff",
            "duration": 0,
            "durationScaleStat": "int",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": false,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {},
                "spriteChange": -1
            }
        },
        "effectMeta": {
            "effectRef": "FindFamiliar",
            "castSfx": "spell-conjure",
            "applySfx": "spell-conjure",
            "unapplyMessage": "Your golem familiar has returned to the ether."
        }
    },
    "FindFamiliarDragon": {
        "tooltip": {
            "name": "Find Familiar Dragon",
            "color": "#000",
            "bgColor": "",
            "desc": "Dragon slays enemies and adds +2 STR/INT",
            "icon": "dragon-spiral"
        },
        "effect": {
            "type": "buff",
            "duration": 0,
            "durationScaleStat": "int",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": false,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {
                    "int": 2,
                    "str": 2
                },
                "spriteChange": -1
            }
        },
        "effectMeta": {
            "effectRef": "FindFamiliar",
            "castSfx": "spell-conjure",
            "applySfx": "spell-conjure",
            "unapplyMessage": "Your dragon has returned to the ether."
        }
    },
    "Shadowmeld": {
        "tooltip": {
            "bgColor": "#00c",
            "desc": "One with the shadows, ready to pounce.",
            "icon": "hidden",
            "color": "#ccc"
        },
        "effect": {
            "type": "buff",
            "duration": 5,
            "extra": {
                "canRemove": true,
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {
            "applyMessage": "You become one with the shadows.",
            "unapplyMessage": "Your shadowmeld is broken!",
            "castSfx": "spell-sight-effect"
        }
    },
    "Disguise": {
        "tooltip": {
            "desc": "Blending in with enemies.",
            "icon": "duality",
            "color": "#111"
        },
        "effect": {
            "type": "buff",
            "duration": 5,
            "extra": {
                "canRemove": true,
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {
            "applyMessage": "You put on a shadowy disguise.",
            "unapplyMessage": "Your disguise is broken!",
            "castSfx": "spell-sight-effect"
        }
    },
    "ChainKunai": {
        "tooltip": {
            "desc": "Chained in place.",
            "name": "Chain Kunai",
            "icon": "harpoon-chain",
            "color": "#111"
        },
        "effect": {
            "type": "debuff",
            "duration": 3,
            "extra": {
                "unique": true,
                "potency": 0
            }
        },
        "effectMeta": {
            "applyMessage": "You are suddenly locked in place!",
            "unapplyMessage": "Your chains fall aside!",
            "effectRef": "ChainKunai"
        }
    },
    "Boost": {
        "tooltip": {
            "name": "",
            "color": "#000",
            "bgColor": "",
            "desc": "+%potency STR, DEX, AGI.",
            "icon": "fist"
        },
        "effect": {
            "type": "buff",
            "duration": 600,
            "durationScaleStat": "str",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {}
            }
        },
        "effectMeta": {
            "effectRef": "Boost",
            "castSfx": "spell-buff-give",
            "applySfx": "spell-buff-magical",
            "castMessage": "You cast Boost on %target.",
            "applyMessage": "You feel your physique improve!",
            "unapplyMessage": "Your physique weakens."
        }
    },
    "Shield": {
        "tooltip": {
            "name": "",
            "color": "#a00",
            "bgColor": "",
            "desc": "Boosted physical and magical resistance by %potency.",
            "icon": "vibrating-shield"
        },
        "effect": {
            "type": "buff",
            "duration": 600,
            "durationScaleStat": "str",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {},
                "spriteChange": -1
            }
        },
        "effectMeta": {
            "effectRef": "Shield",
            "castSfx": "spell-buff-physical",
            "applySfx": "spell-buff-physical",
            "castMessage": "You cast Shield on %target.",
            "applyMessage": "You clasp your hands together and exhale.",
            "unapplyMessage": "Your shield fades."
        }
    },
    "GainSilver": {
        "tooltip": {
            "name": "Gain Silver",
            "color": "",
            "bgColor": "",
            "desc": "Gain silver based on the potency of the item effect.",
            "icon": "receive-money"
        },
        "effect": {
            "type": "useonly",
            "duration": 0,
            "extra": {
                "potency": 100,
                "canRemove": false,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {}
            }
        },
        "effectMeta": {}
    },
    "ArcaneHunger": {
        "tooltip": {
            "name": "Arcane Hunger",
            "color": "#9d15af",
            "bgColor": "",
            "desc": "Spells cost more and deal more damage.",
            "icon": "mouth-watering"
        },
        "effect": {
            "type": "buff",
            "duration": 30,
            "durationScaleStat": "int",
            "durationScaleValue": 100,
            "extra": {
                "potency": 10,
                "canRemove": false,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {}
            }
        },
        "effectMeta": {
            "unapplyMesage": "Your insatiable hunger for the arcane is satiated.",
            "applyMessage": "You hunger for the arcane energies around you!",
            "effectRef": "ArcaneHunger"
        }
    },
    "CurseEXP": {
        "tooltip": {
            "name": "Curse EXP",
            "color": "#ffffff",
            "bgColor": "#00b2ff",
            "desc": "-10% XP gained.",
            "icon": "thunder-skull"
        },
        "effect": {
            "type": "debuff",
            "duration": -1,
            "extra": {
                "potency": 10,
                "canRemove": false,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": true,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {
                    "xpBonusPercent": -10
                }
            }
        },
        "effectMeta": {
            "effectRef": null,
            "applyMessage": "You feel your experiences not taking hold.",
            "unapplyMesage": "Your experiences will now fully take hold!",
            "applySfx": "spell-debuff-receive"
        }
    },
    "CurseSkill": {
        "tooltip": {
            "name": "Curse Skill",
            "color": "#ffffff",
            "bgColor": "#ff0099",
            "desc": "-10% skill gained.",
            "icon": "thunder-skull"
        },
        "effect": {
            "type": "debuff",
            "duration": -1,
            "extra": {
                "potency": 10,
                "canRemove": false,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": true,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {
                    "skillBonusPercent": -10
                }
            }
        },
        "effectMeta": {
            "effectRef": null,
            "applyMessage": "You feel less acute.",
            "unapplyMesage": "Your skill acuteness has returned!",
            "applySfx": "spell-debuff-receive"
        }
    },
    "CurseAGI": {
        "tooltip": {
            "name": "Curse AGI",
            "color": "#ffffff",
            "bgColor": "#ffa400",
            "desc": "-2 AGI.",
            "icon": "thunder-skull"
        },
        "effect": {
            "type": "debuff",
            "duration": -1,
            "extra": {
                "potency": 10,
                "canRemove": false,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": true,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {
                    "agi": -2
                }
            }
        },
        "effectMeta": {
            "effectRef": null,
            "applyMessage": "You feel sluggish.",
            "unapplyMesage": "You no longer feel sluggish!",
            "applySfx": "spell-debuff-receive"
        }
    },
    "CurseSTR": {
        "tooltip": {
            "name": "Curse STR",
            "color": "#ffffff",
            "bgColor": "#ff0000",
            "desc": "-2 STR.",
            "icon": "thunder-skull"
        },
        "effect": {
            "type": "debuff",
            "duration": -1,
            "extra": {
                "potency": 10,
                "canRemove": false,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": true,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {
                    "str": -2
                }
            }
        },
        "effectMeta": {
            "effectRef": null,
            "applyMessage": "You feel weak.",
            "unapplyMesage": "You feel strong again!",
            "applySfx": "spell-debuff-receive"
        }
    },
    "CurseDEX": {
        "tooltip": {
            "name": "Curse DEX",
            "color": "#ffffff",
            "bgColor": "#2c5c97",
            "desc": "-2 DEX.",
            "icon": "thunder-skull"
        },
        "effect": {
            "type": "debuff",
            "duration": -1,
            "extra": {
                "potency": 10,
                "canRemove": false,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": true,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {
                    "dex": -2
                }
            }
        },
        "effectMeta": {
            "effectRef": null,
            "applyMessage": "You feel sloppy.",
            "unapplyMesage": "You no longer feel sloppy!",
            "applySfx": "spell-debuff-receive"
        }
    },
    "CurseINT": {
        "tooltip": {
            "name": "Curse INT",
            "color": "#ffffff",
            "bgColor": "#a000ff",
            "desc": "-2 INT.",
            "icon": "thunder-skull"
        },
        "effect": {
            "type": "debuff",
            "duration": -1,
            "extra": {
                "potency": 10,
                "canRemove": false,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": true,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {
                    "int": -2
                }
            }
        },
        "effectMeta": {
            "effectRef": null,
            "applyMessage": "You feel more stupider.",
            "unapplyMesage": "You feel intelligent again!",
            "applySfx": "spell-debuff-receive"
        }
    },
    "CurseWIS": {
        "tooltip": {
            "name": "Curse WIS",
            "color": "#ffffff",
            "bgColor": "#910157",
            "desc": "-2 WIS.",
            "icon": "thunder-skull"
        },
        "effect": {
            "type": "debuff",
            "duration": -1,
            "extra": {
                "potency": 10,
                "canRemove": false,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": true,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {
                    "wis": -2
                }
            }
        },
        "effectMeta": {
            "effectRef": null,
            "applyMessage": "You feel less wise.",
            "unapplyMesage": "You feel as wise as an owl!",
            "applySfx": "spell-debuff-receive"
        }
    },
    "CurseWIL": {
        "tooltip": {
            "name": "Curse WIL",
            "color": "#ffffff",
            "bgColor": "#0700ff",
            "desc": "-2 WIL.",
            "icon": "thunder-skull"
        },
        "effect": {
            "type": "debuff",
            "duration": -1,
            "extra": {
                "potency": 10,
                "canRemove": false,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": true,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {
                    "wil": -2
                }
            }
        },
        "effectMeta": {
            "effectRef": null,
            "applyMessage": "You feel susceptible.",
            "unapplyMesage": "You no longer feel susceptible to anything!",
            "applySfx": "spell-debuff-receive"
        }
    },
    "AvatarOfMaer": {
        "tooltip": {
            "name": "Avatar of Maer",
            "color": "#000000",
            "bgColor": "#fff000",
            "desc": "Currently an avatar of Maer. +10 WIS",
            "icon": "abstract-010"
        },
        "effect": {
            "type": "buff",
            "duration": -1,
            "durationScaleStat": "int",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": false,
                "unique": "Avatar",
                "doesTick": false,
                "persistThroughDeath": true,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {
                    "wis": 10
                },
                "spriteChange": 350
            }
        },
        "effectMeta": {
            "castMessage": "",
            "applyMessage": "You feel wise beyond your capabilities!",
            "unapplyMesage": "Your wisdom of light fades.",
            "castSfx": "spell-buff-magical"
        }
    },
    "AvatarOfTerir": {
        "tooltip": {
            "name": "Avatar of Terir",
            "color": "#000000",
            "bgColor": "#ff81f8",
            "desc": "Currently an avatar of Terir. +10 INT",
            "icon": "abstract-007"
        },
        "effect": {
            "type": "buff",
            "duration": -1,
            "durationScaleStat": "int",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": false,
                "unique": "Avatar",
                "doesTick": false,
                "persistThroughDeath": true,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {
                    "int": 10
                },
                "spriteChange": 110
            }
        },
        "effectMeta": {
            "castMessage": "",
            "applyMessage": "You feel intelligent beyond your capabilities!",
            "unapplyMesage": "Your intelligence of power fades.",
            "castSfx": "spell-buff-magical"
        }
    },
    "AvatarOfTai": {
        "tooltip": {
            "name": "Avatar of Tai",
            "color": "#000000",
            "bgColor": "#ff4d4d",
            "desc": "Currently an avatar of Tai. +10 STR",
            "icon": "abstract-038"
        },
        "effect": {
            "type": "buff",
            "duration": -1,
            "durationScaleStat": "int",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": false,
                "unique": "Avatar",
                "doesTick": false,
                "persistThroughDeath": true,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {
                    "str": 10
                },
                "spriteChange": 935
            }
        },
        "effectMeta": {
            "castMessage": "",
            "applyMessage": "You feel strong beyond your capabilities!",
            "unapplyMesage": "Your strength fades.",
            "castSfx": "spell-buff-magical"
        }
    },
    "AvatarOfMaji": {
        "tooltip": {
            "name": "Avatar of Maji",
            "color": "#000000",
            "bgColor": "#4dff66",
            "desc": "Currently an avatar of Maji. +10 AGI",
            "icon": "abstract-011"
        },
        "effect": {
            "type": "buff",
            "duration": -1,
            "durationScaleStat": "int",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": false,
                "unique": "Avatar",
                "doesTick": false,
                "persistThroughDeath": true,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {
                    "agi": 10
                },
                "spriteChange": 330
            }
        },
        "effectMeta": {
            "castMessage": "",
            "applyMessage": "You feel agile beyond your capabilities!",
            "unapplyMesage": "Your agility fades.",
            "castSfx": "spell-buff-magical"
        }
    },
    "AvatarOfRea": {
        "tooltip": {
            "name": "Avatar of Rea",
            "color": "#000000",
            "bgColor": "#00f4ff",
            "desc": "Currently an avatar of Rea. +10 WIL",
            "icon": "abstract-041"
        },
        "effect": {
            "type": "buff",
            "duration": -1,
            "durationScaleStat": "int",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": false,
                "unique": "Avatar",
                "doesTick": false,
                "persistThroughDeath": true,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {
                    "wil": 10
                },
                "spriteChange": 145
            }
        },
        "effectMeta": {
            "castMessage": "",
            "applyMessage": "You feel resistant beyond your capabilities!",
            "unapplyMesage": "Your resistance fades.",
            "castSfx": "spell-buff-magical"
        }
    },
    "AvatarOfArs": {
        "tooltip": {
            "name": "Avatar of Ars",
            "color": "#000000",
            "bgColor": "#ffaf00",
            "desc": "Currently an avatar of Ars. +10 DEX",
            "icon": "abstract-027"
        },
        "effect": {
            "type": "buff",
            "duration": -1,
            "durationScaleStat": "int",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": false,
                "unique": "Avatar",
                "doesTick": false,
                "persistThroughDeath": true,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {
                    "dex": 10
                },
                "spriteChange": 90
            }
        },
        "effectMeta": {
            "castMessage": "",
            "applyMessage": "You feel dextrous beyond your capabilities!",
            "unapplyMesage": "Your dexterity fades.",
            "castSfx": "spell-buff-magical"
        }
    },
    "CostumeGargoyle": {
        "tooltip": {
            "name": "Gargoyle Costume",
            "color": "#000000",
            "bgColor": "#ff71a2",
            "desc": "Cosplaying as a Gargoyle!",
            "icon": "bully-minion"
        },
        "effect": {
            "type": "buff",
            "duration": -1,
            "durationScaleStat": "int",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": "Costume",
                "doesTick": false,
                "persistThroughDeath": true,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {},
                "spriteChange": 120
            }
        },
        "effectMeta": {
            "castMessage": "",
            "applyMessage": "You've acquired a strange garb!",
            "unapplyMesage": "Your strange garb magically vanishes.",
            "castSfx": "spell-buff-magical"
        }
    },
    "CostumeMummy": {
        "tooltip": {
            "name": "Mummy Costume",
            "color": "#000000",
            "bgColor": "#ffb648",
            "desc": "Cosplaying as a Mummy!",
            "icon": "bully-minion"
        },
        "effect": {
            "type": "buff",
            "duration": -1,
            "durationScaleStat": "int",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": "Costume",
                "doesTick": false,
                "persistThroughDeath": true,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {},
                "spriteChange": 155
            }
        },
        "effectMeta": {
            "castMessage": "",
            "applyMessage": "You've acquired a strange garb!",
            "unapplyMesage": "Your strange garb magically vanishes.",
            "castSfx": "spell-buff-magical"
        }
    },
    "CostumeZombie": {
        "tooltip": {
            "name": "Zombie Costume",
            "color": "#000000",
            "bgColor": "#ff3f3f",
            "desc": "Cosplaying as a Zombie!",
            "icon": "bully-minion"
        },
        "effect": {
            "type": "buff",
            "duration": -1,
            "durationScaleStat": "int",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": "Costume",
                "doesTick": false,
                "persistThroughDeath": true,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {},
                "spriteChange": 1465
            }
        },
        "effectMeta": {
            "castMessage": "",
            "applyMessage": "You've acquired a strange garb!",
            "unapplyMesage": "Your strange garb magically vanishes.",
            "castSfx": "spell-buff-magical"
        }
    },
    "CostumeSkeleton": {
        "tooltip": {
            "name": "Skeleton Costume",
            "color": "#000000",
            "bgColor": "#dcffc4",
            "desc": "Cosplaying as a Skeleton!",
            "icon": "bully-minion"
        },
        "effect": {
            "type": "buff",
            "duration": -1,
            "durationScaleStat": "int",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": "Costume",
                "doesTick": false,
                "persistThroughDeath": true,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {},
                "spriteChange": 1015
            }
        },
        "effectMeta": {
            "castMessage": "",
            "applyMessage": "You've acquired a strange garb!",
            "unapplyMesage": "Your strange garb magically vanishes.",
            "castSfx": "spell-buff-magical"
        }
    },
    "CostumeGlorb": {
        "tooltip": {
            "name": "Glorb Costume",
            "color": "#000000",
            "bgColor": "#34b41c",
            "desc": "Cosplaying as a Glorb!",
            "icon": "bully-minion"
        },
        "effect": {
            "type": "buff",
            "duration": -1,
            "durationScaleStat": "int",
            "durationScaleValue": 100,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": "Costume",
                "doesTick": false,
                "persistThroughDeath": true,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {},
                "spriteChange": 140
            }
        },
        "effectMeta": {
            "castMessage": "",
            "applyMessage": "You've acquired a strange garb!",
            "unapplyMesage": "Your strange garb magically vanishes.",
            "castSfx": "spell-buff-magical"
        }
    },
    "WaterBreathing": {
        "tooltip": {
            "name": "Water Breathing",
            "color": "#0aa",
            "bgColor": "",
            "desc": "Swimming and not drowning!",
            "icon": "drowning"
        },
        "effect": {
            "type": "buff",
            "duration": 600,
            "durationScaleStat": "int",
            "durationScaleValue": 150,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {},
                "spriteChange": -1
            }
        },
        "effectMeta": {
            "castSfx": "spell-buff-physical",
            "applySfx": "spell-buff-physical",
            "castMessage": "You cast Water Breathing on %target.",
            "applyMessage": "You feel gills grow on your neck!",
            "unapplyMessage": "Your body returns to the ground."
        }
    },
    "LavaBreathing": {
        "tooltip": {
            "name": "Lava Breathing",
            "color": "#aa2c00",
            "bgColor": "",
            "desc": "Swimming in lava!",
            "icon": "drowning"
        },
        "effect": {
            "type": "buff",
            "duration": 600,
            "durationScaleStat": "int",
            "durationScaleValue": 150,
            "extra": {
                "potency": 0,
                "canRemove": true,
                "unique": true,
                "doesTick": false,
                "persistThroughDeath": false,
                "canOverlapUniqueIfEquipped": false,
                "charges": 0,
                "statChanges": {},
                "spriteChange": -1
            }
        },
        "effectMeta": {
            "castSfx": "spell-buff-physical",
            "applySfx": "spell-buff-physical",
            "castMessage": "You cast Lava Breathing on %target.",
            "applyMessage": "Your neck is seared by hot gills!",
            "unapplyMessage": "Your body returns to the ground."
        }
    }
}