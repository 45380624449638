<div
  class="macro-container"
  [class.active]="isActive()"
  [class.has-macro]="macroRef()"
  [class.disable-effects]="disableEffects()"
>
  <div class="macro" [mdePopoverTriggerFor]="macroTooltipTemplate">
    <app-icon
      [name]="iconName()"
      [disabled]="disabled()"
      [bgColor]="background()"
      [fgColor]="foreground()"
    ></app-icon>

    @if (cooldownDisplay(); as cooldown) {
    <div class="cooldown">{{ cooldown }} s</div>
    }

    <!---->
    @if (macroKeybind(); as macroKey) {
    <div class="keybind">
      {{ macroKey }}
    </div>
    }
  </div>

  <mde-popover
    #macroTooltipTemplate="mdePopover"
    [mdePopoverPositionY]="'above'"
    [mdePopoverTriggerOn]="macroRef() && showTooltip() ? 'hover' : 'none'"
    [mdePopoverOverlapTrigger]="false"
    [mdePopoverEnterDelay]="1000"
  >
    <mat-card class="tooltip-card">
      <mat-card-title>
        <strong>{{ macroName() || 'Empty' }}</strong>
      </mat-card-title>
      <mat-card-content>
        @if(player(); as player) {
        {{ reformatTooltipTextForPlayer(player, macroTooltip()) }}
        }
      </mat-card-content>
    </mat-card>
  </mde-popover>
</div>
